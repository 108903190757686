<template>
  <el-select
    v-model="productModel"
    @change="productChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.PRODUCT')"
    :filterable="filterable"
    :disabled="disabled"
    remote
    :remote-method="getProducts"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.PRODUCT')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="(product, key) in products"
      :key="key"
      :value="product.id"
      :label="`${product.code} - ${product.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { difference } from "lodash";

export default {
  name: "product-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    product: {
      type: String,
      default: null,
      description: "Product id",
    },
    /** we use product and product object because we have multiple kind of products : supplier-products, warehouse-products, products */
    productObject: {
      type: Object,
      default: null,
      description: "Product object must have a field product",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterWarehouse: {
      type: String,
      default: null,
      description: "Warehouse id",
    },
    filterProductsNotInWarehouseId: {
      type: String,
      default: null,
      description: "Product not found in wharehouse",
    },
    filterSupplier: {
      type: String,
      default: null,
      description: "Supplier id",
    },
    filterProductsNotInSupplierId: {
      type: String,
      default: null,
      description: "Product not found for supplier",
    },
    filterIds: {
      type: Array,
      default: null,
      description: "products ids",
    },
    filterIdsNotIn: {
      type: Array,
      default: () => [],
      description: "Id not in",
    },
    filterCanBeSold: {
      type: [Boolean, null],
      default: null,
      description: "Can the product be sold",
    },
    filterCanBePurchased: {
      type: [Boolean, null],
      default: null,
      description: "Can the product be purchased",
    },
  },

  data() {
    let productModel = null;
    if (this.product) {
      productModel = this.product;
    } else if (this.productObject) {
      productModel = this.productObject.product_id;
    }
    return {
      productModel: productModel,
      products: {},
      loading: false,
    };
  },

  setup() {},

  created() {
    if (this.product) {
      this.getProducts(null, this.product);
    } else if (this.productObject) {
      this.getProducts(null, this.productObject.product_id);
    } else {
      this.getProducts();
    }
  },
  mounted() {
    this.getProducts();
  },

  methods: {
    getProductsDebounced: _.debounce(function () {
      this.getProducts();
    }, 300),

    async getProducts(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          include: "taxGroups,unitOfMeasure",
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterProductsNotInWarehouseId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              productsNotInWarehouse: this.filterProductsNotInWarehouseId,
            },
          };
        }
        if (this.filterProductsNotInSupplierId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              productsNotInSupplier: this.filterProductsNotInSupplierId,
            },
          };
        }
        if (this.filterSupplier) {
          params = {
            ...params,
            filter: { ...params.filter, supplier: this.filterSupplier },
          };
        }
        if (this.filterWarehouse) {
          params = {
            ...params,
            filter: { ...params.filter, warehouse: this.filterWarehouse },
          };
        }
        if (this.filterIds) {
          params = {
            ...params,
            filter: { ...params.filter, ids: this.filterIds },
          };
        }
        if (this.filterIdsNotIn.length > 0) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              idsNotIn: this.filterIdsNotIn,
            },
          };
        }
        if (this.filterCanBeSold != null) {
          params = {
            ...params,
            filter: { ...params.filter, can_be_sold: this.filterCanBeSold },
          };
        }
        if (this.filterCanBePurchased != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              can_be_purchased: this.filterCanBePurchased,
            },
          };
        }

        await this.$store.dispatch("products/list", params);
        const productsArr = await this.$store.getters["products/list"];
        const productsData = {};
        productsArr.forEach((product) => {
          productsData[product.id] = product;
        });
        this.products = productsData;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    productChanged(productId) {
      const product = Object.values(this.products).find(
        (item) => item.id === productId
      );
      this.$emit("productChanged", productId, product);
    },
  },

  watch: {
    product(product) {
      if (product) {
        if (product !== this.productModel) {
          this.getProducts(null, product);
        }
        this.productModel = product;
      } else {
        this.productModel = null;
      }
    },
    productObject(productObject) {
      if (productObject) {
        if (productObject.product_id !== this.productModel) {
          this.getProducts(null, productObject.product_id);
        }
        this.productModel = productObject.product_id;
      } else {
        this.productModel = null;
      }
    },
    filterProductsNotInWarehouseId() {
      this.getProducts();
    },
    filterProductsNotInSupplierId() {
      this.getProducts();
    },
    filterOrganization(filterOrganization) {
      this.getProducts();
    },
    filterReseller(filterReseller) {
      this.getProducts();
    },
    filterSupplier(filterSupplier) {
      this.getProducts();
    },
    filterWarehouse(filterWarehouse) {
      this.getProducts();
    },
    filterIds(filterIds) {
      this.getProducts();
    },
    filterCanBeSold(filterCanBeSold) {
      this.getProducts();
    },
    filterCanBePurchased(filterCanBePurchased) {
      this.getProducts();
    },
  },
};
</script>
