<template>
  <div>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"
      >
        <product-selector
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PRODUCTS)"
          :filterOrganization="stockMovement.organization.id"
          :filterWarehouse="getWarehouse()"
          @productChanged="(productId) => (selectedProduct = productId)"
        />
      </div>
      <el-table
        class="table-responsive align-items-center table-flush stock-movement-product-list"
        header-row-class-name="thead-light"
        :data="filteredStockMovementItemsModel"
        type="expand"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column
          :label="$t('COMMON.PRODUCT')"
          prop="product.name"
          min-width="80px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <product-selector
                :filterOrganization="stockMovement.organization.id"
                :product="stockMovementItemsModel[row.id].storable.id"
                :filterWarehouse="getWarehouse()"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                :filterIdsNotIn="existingProducts"
                @productChanged="
                  (productId, product) => {
                    stockMovementItemsModel[row.id].storable = product;
                    updateStockMovementItemDebounced(row.id);
                  }
                "
              />
            </base-input>
            <object-link
              v-if="row.storable && row.id > 0"
              :object="row.storable"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.QUANTITY')"
          prop="quantity"
          min-width="40px"
        >
          <template v-slot="{ row }">
            <base-input
              v-if="
                stockMovement.status == STATUS_DRAFT &&
                stockMovementItemsModel[row.id]
              "
              class="my-5"
              :placeholder="$t('COMMON.QUANTITY')"
              v-model="stockMovementItemsModel[row.id].quantity"
              input-classes="form-control-alternative"
              @change="updateStockMovementItemDebounced(row.id)"
            />
            <span v-if="stockMovement.status != STATUS_DRAFT">
              {{ stockMovementItemsModel[row.id].quantity }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('PRODUCTS.UNIT_OF_MEASURE_UNIT')"
          prop="quantity"
          min-width="40px"
        >
          <template v-slot="{ row }">
            <unit-of-measure-unit-selector
              v-if="
                stockMovement.status == STATUS_DRAFT &&
                stockMovementItemsModel[row.id]?.unitOfMeasureId
              "
              :filterOrganization="stockMovement.organization.id"
              :filterUnitOfMeasure="
                stockMovementItemsModel[row.id].unitOfMeasureId
              "
              :unit="stockMovementItemsModel[row.id].unitOfMeasureUnit?.id"
              :allowNone="false"
              :showAll="false"
              @unitChanged="
                (unitId, unit) => {
                  stockMovementItemsModel[row.id].unitOfMeasureUnit.id = unitId;
                  updateStockMovementItemDebounced(row.id);
                }
              "
            />
            <object-link
              v-if="
                stockMovement.status != STATUS_DRAFT &&
                stockMovementItemsModel[row.id].unitOfMeasureUnit
              "
              :object="stockMovementItemsModel[row.id].unitOfMeasureUnit"
            />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="20px"
          v-if="stockMovement.status == STATUS_DRAFT"
        >
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Loading"
              placement="top"
              v-if="
                stockMovementItemsModel[row.id] &&
                stockMovementItemsModel[row.id].loading
              "
            >
              <a
                type="text"
                class="table-action table-action-delete"
                data-toggle="tooltip"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
            >
              <a
                type="text"
                @click="deleteStockMovementItem(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div class="row px-3 mt-3" v-if="stockMovement.status == STATUS_DRAFT">
        <base-button
          type="info"
          class="btn-sm mr-1 col-12"
          style="width: 100%"
          @click="
            () => {
              addLine();
            }
          "
          v-if="stockMovement.movement_type != TYPE_CORRECT"
        >
          <i class="fas fa-plus"></i>
          {{ $t("PRODUCTS.ADD_PRODUCT") }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  TYPE_CORRECT,
  TYPE_ADD,
  TYPE_REMOVE,
  TYPE_MOVE,
  STATUS_DRAFT,
} from "@/constants/stockMovements";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ProductSelector from "@/components/ProductSelector.vue";
import UnitOfMeasureUnitSelector from "@/components/UnitOfMeasureUnitSelector.vue";

export default {
  name: "product-list-table",

  components: {
    ProductSelector,
    UnitOfMeasureUnitSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    stockMovement: {
      type: Object,
      default: null,
      description: "StockMovement",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      filteredStockMovementItemsModel: [],
      loading: false,
      selectedProduct: null,
      stockMovementItemsModel: {},
      deletedStockMovementItems: {},
      TYPE_CORRECT: TYPE_CORRECT,
      STATUS_DRAFT: STATUS_DRAFT,
      existingProducts: [],
    };
  },

  computed: {},

  watch: {
    stockMovement: {
      handler: "refreshStockMovementItems",
      immediate: true,
    },
    selectedProduct: {
      handler: "filterStockMovementItems",
      immediate: true,
    },
  },

  mounted() {
    this.refreshStockMovementItems();
  },

  methods: {
    updateStockMovementItemDebounced: _.debounce(function (id) {
      this.updateStockMovementItem(id);
    }, 300),

    getWarehouse() {
      if (this.stockMovement.movement_type == TYPE_CORRECT) {
        return this.stockMovement.sourceWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_ADD) {
        if (
          this.stockMovement.destinationWarehouse.allow_unregistered_products
        ) {
          return null;
        }
        return this.stockMovement.destinationWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_MOVE) {
        return this.stockMovement.sourceWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_REMOVE) {
        return this.stockMovement.sourceWarehouse.id;
      }
    },

    async filterStockMovementItems() {
      this.filteredStockMovementItemsModel = Object.values(
        this.stockMovementItemsModel
      ).filter((item) => {
        if (item.id < 0) {
          return true;
        }
        if (this.selectedProduct) {
          if (this.selectedProduct != item.storable.id) {
            return false;
          }
        }
        return true;
      });
    },

    async deleteStockMovementItem(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("STOCK_MOVEMENTS.DELETE_THIS_STOCK_MOVEMENT_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
        try {
          await this.$store.dispatch("stockMovementItems/destroy", row.id);
        } catch (error) {
          await this.showRequestError(error);
        }
      }

      this.$emit("stockMovementItemsUpdated");
    },

    async updateStockMovementItem(id) {
      this.stockMovementItemsModel[id].loading = true;
      const stockMovementItemData = this.stockMovementItemsModel[id];
      try {
        if (stockMovementItemData.id > 0) {
          await this.$store.dispatch(
            "stockMovementItems/update",
            stockMovementItemData
          );
        } else {
          if (
            stockMovementItemData.storable.id &&
            stockMovementItemData.stockMovement.id
          ) {
            delete stockMovementItemData.id;
            await this.$store.dispatch(
              "stockMovementItems/add",
              stockMovementItemData
            );
          }
        }
      } catch (error) {
        await this.showRequestError(error);
      }
      this.$emit("stockMovementItemsUpdated");
    },

    addLine() {
      let newLinesIds = Object.values(this.stockMovementItemsModel)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newStockMovementItem = {
        type: "stock-movement-items",
        id: newLineId,
        quantity: 0,
        relationshipNames: ["storable", "stockMovement"],
        storable: {
          type: "products",
          id: null,
        },
        stockMovement: {
          type: "stock-movements",
          id: this.stockMovement.id,
        },
        unitOfMeasureUnit: {
          type: "unit-of-measure-units",
          id: null,
        },
        loading: false,
      };
      this.stockMovementItemsModel[newLineId] =
        _.cloneDeep(newStockMovementItem);
      this.filterStockMovementItems();
    },

    refreshStockMovementItems() {
      const stockMovementItemsModelData = {};
      for (const item of this.stockMovement.items) {
        stockMovementItemsModelData[item.id] = {
          type: "stock-movement-items",
          id: item.id,
          quantity: item.quantity,
          relationshipNames: ["storable", "stockMovement", "unitOfMeasureUnit"],
          storable: {
            type: item.storable.type,
            id: item.storable.id,
            name: item.storable.name,
          },
          stockMovement: {
            type: "stock-movements",
            id: item.stockMovement.id,
          },
          unitOfMeasureUnit: {
            type: "unit-of-measure-units",
            id: item.unitOfMeasureUnit?.id,
            name: item.unitOfMeasureUnit?.name,
          },
          unitOfMeasureId: item.storable.unitOfMeasure?.id,
          loading: false,
        };
      }
      this.existingProducts = this.stockMovement.items.map(
        (item) => item.storable.id
      );
      this.stockMovementItemsModel = stockMovementItemsModelData;
      this.filterStockMovementItems();
    },
  },
};
</script>

<style>
.stock-movement-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
.stock-movement-product-list .form-group.taxes-selector .remove-taxes-button {
  display: flex;
  align-items: center;
}
</style>
