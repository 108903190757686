var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PRODUCTS))?_c('product-selector',{attrs:{"filterOrganization":_vm.stockMovement.organization.id,"filterWarehouse":_vm.getWarehouse()},on:{"productChanged":(productId) => (_vm.selectedProduct = productId)}}):_vm._e()],1),_c('el-table',{staticClass:"table-responsive align-items-center table-flush stock-movement-product-list",attrs:{"header-row-class-name":"thead-light","data":_vm.filteredStockMovementItemsModel,"type":"expand"}},[(_vm.loading)?_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.PRODUCT'),"prop":"product.name","min-width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.id < 0)?_c('base-input',[_c('product-selector',{attrs:{"filterOrganization":_vm.stockMovement.organization.id,"product":_vm.stockMovementItemsModel[row.id].storable.id,"filterWarehouse":_vm.getWarehouse(),"allowNone":false,"showAll":false,"disabled":row.id > 0,"filterIdsNotIn":_vm.existingProducts},on:{"productChanged":(productId, product) => {
                  _vm.stockMovementItemsModel[row.id].storable = product;
                  _vm.updateStockMovementItemDebounced(row.id);
                }}})],1):_vm._e(),(row.storable && row.id > 0)?_c('object-link',{attrs:{"object":row.storable}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.QUANTITY'),"prop":"quantity","min-width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
              _vm.stockMovement.status == _vm.STATUS_DRAFT &&
              _vm.stockMovementItemsModel[row.id]
            )?_c('base-input',{staticClass:"my-5",attrs:{"placeholder":_vm.$t('COMMON.QUANTITY'),"input-classes":"form-control-alternative"},on:{"change":function($event){return _vm.updateStockMovementItemDebounced(row.id)}},model:{value:(_vm.stockMovementItemsModel[row.id].quantity),callback:function ($$v) {_vm.$set(_vm.stockMovementItemsModel[row.id], "quantity", $$v)},expression:"stockMovementItemsModel[row.id].quantity"}}):_vm._e(),(_vm.stockMovement.status != _vm.STATUS_DRAFT)?_c('span',[_vm._v(" "+_vm._s(_vm.stockMovementItemsModel[row.id].quantity)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('PRODUCTS.UNIT_OF_MEASURE_UNIT'),"prop":"quantity","min-width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
              _vm.stockMovement.status == _vm.STATUS_DRAFT &&
              _vm.stockMovementItemsModel[row.id]?.unitOfMeasureId
            )?_c('unit-of-measure-unit-selector',{attrs:{"filterOrganization":_vm.stockMovement.organization.id,"filterUnitOfMeasure":_vm.stockMovementItemsModel[row.id].unitOfMeasureId,"unit":_vm.stockMovementItemsModel[row.id].unitOfMeasureUnit?.id,"allowNone":false,"showAll":false},on:{"unitChanged":(unitId, unit) => {
                _vm.stockMovementItemsModel[row.id].unitOfMeasureUnit.id = unitId;
                _vm.updateStockMovementItemDebounced(row.id);
              }}}):_vm._e(),(
              _vm.stockMovement.status != _vm.STATUS_DRAFT &&
              _vm.stockMovementItemsModel[row.id].unitOfMeasureUnit
            )?_c('object-link',{attrs:{"object":_vm.stockMovementItemsModel[row.id].unitOfMeasureUnit}}):_vm._e()]}}])}),(_vm.stockMovement.status == _vm.STATUS_DRAFT)?_c('el-table-column',{attrs:{"align":"center","min-width":"20px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[(
              _vm.stockMovementItemsModel[row.id] &&
              _vm.stockMovementItemsModel[row.id].loading
            )?_c('el-tooltip',{attrs:{"content":"Loading","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"type":"text","data-toggle":"tooltip"}},[_c('i',{staticClass:"fas fa-spinner fa-spin"})])]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_EDIT_PRODUCTS))?_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteStockMovementItem(row)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)}}],null,false,3873656496)}):_vm._e()],1),(_vm.stockMovement.status == _vm.STATUS_DRAFT)?_c('div',{staticClass:"row px-3 mt-3"},[(_vm.stockMovement.movement_type != _vm.TYPE_CORRECT)?_c('base-button',{staticClass:"btn-sm mr-1 col-12",staticStyle:{"width":"100%"},attrs:{"type":"info"},on:{"click":() => {
            _vm.addLine();
          }}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("PRODUCTS.ADD_PRODUCT"))+" ")]):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }