var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-bdelivery-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.stockMovement.code)+" "),(_vm.stockMovement.status === _vm.STATUS_DRAFT)?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.editStockMovement()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(
                  _vm.stockMovement.status === _vm.STATUS_DRAFT &&
                  _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_STOCK_MOVEMENTS)
                )?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm"},on:{"click":function($event){return _vm.deleteStockMovement()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                  _vm.stockMovement.items.length > 0 &&
                  _vm.stockMovement.status === _vm.STATUS_DRAFT
                )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.validateStockMovement()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-check-bold"}),_vm._v(" "+_vm._s(_vm.$t("STOCK_MOVEMENTS.VALIDATE"))+" ")])]):_vm._e(),(_vm.stockMovement.status === _vm.STATUS_VALIDATED)?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.cancelStockMovement()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-fat-remove"}),_vm._v(" "+_vm._s(_vm.$t("STOCK_MOVEMENTS.CANCEL"))+" ")])]):_vm._e()],1)]),_c('div',{staticClass:"col-4 text-right"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RETURN_TO_LIST"))+" ")]),_c('notification-subscription',{attrs:{"objectType":'stock-movements',"objectId":_vm.stockMovement.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}})],1)])]),_c('div',[_c('div',{staticClass:"col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"},[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"details"}},[_c('card',{attrs:{"shadow":""}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-cloud-upload-96"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('stock-movement-view-global',{attrs:{"stockMovement":_vm.stockMovement}})],1),_c('tab-pane',{attrs:{"title":"details","id":"2","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-bullet-list-67"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.DETAILS"))+" ")]),_c('stock-movement-view-details',{attrs:{"stockMovement":_vm.stockMovement},on:{"stockMovementItemsUpdated":_vm.get}})],1),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_FILES) &&
                  _vm.stockMovement.organization
                )?_c('tab-pane',{attrs:{"title":"files","id":"998"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-folder-17"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.FILES"))+" ")]),_c('list-file-component',{attrs:{"object":_vm.stockMovement}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOGS))?_c('tab-pane',{attrs:{"title":"logs","id":"999"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.LOGS"))+" ")]),_c('stock-movement-view-logs',{attrs:{"stockMovement":_vm.stockMovement}})],1):_vm._e()],1)],1)],1)])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }